@import '~antd/dist/antd.less';

#root {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
}

//body::-webkit-scrollbar {
//  width: 10px;
//  height: 10px;
//  background: #000000;
//}
//
//body::-webkit-scrollbar-track {
//  background: rgba(58, 58, 58, 0.66);
//  width: 8px;
//  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2)
//}
//
//body::-webkit-scrollbar-thumb {
//  max-width: 6px;
//  max-height: 6px;
//  background: #545656;
//  border-radius: 10px;
//  margin: 2px;
//}

.page-breadcrumb {
  line-height: 26px;
  font-size: 20px;
  padding-bottom: 18px;
  font-weight: bold;
  color: #303133;
}

.table-card {
  width: auto;
  height: auto;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 20px;

  .header {
    display: flex;
    width: 100%;
    .search {
      flex:1
    }
    .actions {
      display: flex;
      width: auto;
      margin-left: 135px;
      align-items: flex-start;
    }
  }
  .table-card-body {
    width: 100%;
  }
  .table-card-pagination {
    display: flex;
    justify-content: flex-end;
  }
}

.tableImg{
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.tableHandle{
    color: #306BFF;
    cursor: pointer;
}

.flex-right{
  display: flex;
  justify-content: flex-end;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  cursor: pointer;
  user-select: none;
}

.button:active{
  opacity: 0.8;
}

.suspense-spin {
  width:  100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@primary-color: #306BFF;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;