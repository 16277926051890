@font-face {
  font-family: "iconfont"; /* Project id 3209119 */
  src: url('iconfont.woff2?t=1646722419064') format('woff2'),
       url('iconfont.woff?t=1646722419064') format('woff'),
       url('iconfont.ttf?t=1646722419064') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconfont-xitong:before {
  content: "\e670";
}

.iconfont-yulan1:before {
  content: "\e66a";
}

.iconfont-yulan:before {
  content: "\e671";
}

.iconfont-shebei:before {
  content: "\e667";
}

.iconfont-jiemu:before {
  content: "\e669";
}

.iconfont-quanjujiance:before {
  content: "\e662";
}

.iconfont-shenhe:before {
  content: "\e63c";
}

.iconfont-shanchu:before {
  content: "\e664";
}

.iconfont-bianji2:before {
  content: "\e665";
}

.iconfont-xiangqing:before {
  content: "\e666";
}

.iconfont-fabujihua:before {
  content: "\e604";
}

.iconfont-xiazai:before {
  content: "\e60f";
}

.iconfont-liebiaomoshi:before {
  content: "\e61b";
}

.iconfont-datumoshi:before {
  content: "\e61c";
}

.iconfont-bianji:before {
  content: "\e668";
}

