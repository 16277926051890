@import '../src/assets/iconfont/iconfont.css';
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
}

html {
    height: 100%;
    overflow: auto;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    margin: 0;
    color: #333;
    font-size: 14px;
    min-width: 1200px;
    min-height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    -webkit-font-smoothing: antialiased;
    background-color: #EDEDED;
}

*, :before, :after {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

ul, li {
    list-style: none;
}

input, button, textarea {
    padding: 0;
    border: none;
    outline: none;
}

textarea {
    resize: none;
    color: #333;
    font-family: inherit;
}

button {
    cursor: pointer;
    transition: all .3s linear;
    background-color: transparent;
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

.type-area {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.clearfix {
    zoom: 1;
}

.clearfix::after {
    height: 0;
    content: '';
    clear: both;
    font-size: 0;
    display: block;
    line-height: 0;
    visibility: hidden;
}

.hidden {
    display: none;
}

/* select */
.select-dropdown {
    max-height: 64px;
    overflow: hidden;
    position: absolute;
}

.select-dropdown-container {
    border-radius: 3px;
    background-color: #FFF;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
}

.row_box {
    display: flex;
    align-items: center;
    width: 100%;
}

.cdx-list--ordered, .cdx-list--ordered li {
    list-style: decimal !important;
}

.cdx-list--unordered, .cdx-list--unordered li {
    list-style: disc !important;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
}

input[type="checkbox"].checkbox-circle {
    position: relative;
    width: 18px;
    height: 18px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    border-radius: 50%;
    padding: 9px;
    cursor: pointer;
    visibility: hidden;
}

input[type="checkbox"].checkbox-circle:after {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    content: '';
    margin: 0;
    color: #fff;
    display: inline-block;
    visibility: visible;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
}

input[type="radio"].radio-circle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    margin: 0;
    border-radius: 50%;
    cursor: pointer;
    visibility: hidden;
}


input[type="radio"].radio-circle:after {
    width: 100%;
    height: 100%;
    content: '';
    margin: 0;
    color: #fff;
    display: inline-block;
    visibility: visible;
    border-radius: 50%;
    background: transparent;
}

input[type="radio"]:checked.radio-circle:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #2F7354;
}


